import { Select, Divider, message, PageHeader, Row, Col, Tag } from 'antd';
import React, { useEffect, useState, FC } from 'react';
import { Client } from '@elm-street-technology/crm-axios-client';
import { SearchOutlined } from '@ant-design/icons';

import { emDash } from 'src/common/utils';
import { tagApi } from 'src/common/services';
import { getFetchMessageText } from 'src/common/utils/textUtils';

interface Props {
  client: Client;
  onTagSelectChange: (value: string[]) => void;
}

export const SourceTagsSection: FC<Props> = ({ client, onTagSelectChange }) => {
  const [tagsOptions, setTagsOptions] = useState<string[]>([]);

  useEffect(() => {
    if (client) {
      tagApi
        .listTags()
        .then(({ data }) => setTagsOptions(data))
        .catch(error => message.error(getFetchMessageText('tags').error));
    }
  }, [client.id]);

  return (
    <PageHeader footer={<Divider />}>
      <Row gutter={[8, 8]}>
        <Col span={4} style={{ fontSize: 16 }}>
          Source
        </Col>
        <Col span={20} style={{ fontSize: 16, color: '#6D7278' }}>
          {client.source || emDash}
        </Col>

        <Col xs={24} sm={4} style={{ fontSize: 16 }}>
          Tags
        </Col>
        <Col xs={24} sm={20}>
          <Select
            style={{ width: '100%' }}
            mode="tags"
            size="large"
            defaultValue={client.tags}
            tagRender={props => (
              <Tag {...props} color="blue" style={{ borderRadius: 11 }}>
                {props.label}
              </Tag>
            )}
            maxTagTextLength={15}
            dropdownMatchSelectWidth
            onChange={onTagSelectChange}
            getPopupContainer={trigger => trigger.parentNode}
            showArrow
            suffixIcon={<SearchOutlined />}
            options={tagsOptions.map(tag => ({
              label: tag,
              value: tag
            }))}
          />
        </Col>
      </Row>
    </PageHeader>
  );
};
