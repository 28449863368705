import * as React from 'react';

const SvgElevateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="128px" height="40px" viewBox="0 0 128 40">
    <title>Logo/Elevate Red</title>
    <desc>Created with Sketch.</desc>
    <g
      id="CRM-My-Sites-Themes"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Logo/Elevate-Red">
        <g id="elevate-copy">
          <path
            d="M2.93564878,31.0891864 C2.02579855,30.1833737 1.29578562,29.1105459 0.784848669,27.9283682 C-0.796038437,24.1690218 0.0563589801,19.8169128 2.93564878,16.9469689 C3.85438988,16.0370735 4.93458297,15.3097086 6.11986636,14.8028262 C7.34402448,14.2720135 8.66277673,13.9992294 9.99518188,14.0012166 C12.6578228,13.9588569 15.2203517,15.024232 17.0805504,16.9469689 C17.9816591,17.8645857 18.7026499,18.9458818 19.2055151,20.1338557 C19.7324546,21.3687849 20.0028363,22.6996235 19.9999776,24.0441463 C19.9966139,24.964855 19.2652568,25.7143885 18.3529457,25.7320884 L3.49756953,25.7320884 C3.86565541,27.1531044 4.69866075,28.4069998 5.861512,29.2904528 C7.0358548,30.2353657 8.49985926,30.7377579 10.0016407,30.7111917 C12.4430895,30.7111917 14.3269234,29.9617193 15.6531425,28.4627746 C15.9346155,28.118885 16.3481968,27.9125992 16.7899018,27.8957825 C17.2249965,27.8605844 17.6557754,28.0039069 17.9847907,28.2933287 C18.657379,28.9023899 18.715138,29.9456056 18.1139679,30.6264687 L18.1139679,30.6264687 C16.1763101,32.8748858 13.4442127,33.9958358 10.0210173,33.9958358 C7.35231057,34.0751723 4.77367402,33.0178512 2.9162722,31.0826693 L2.93564878,31.0891864 Z M16.4798765,22.349687 C15.7247841,19.3542149 13.0519277,17.2573464 9.98872302,17.2573464 C6.92551838,17.2573464 4.25266195,19.3542149 3.49756953,22.349687 L16.4798765,22.349687 Z"
            id="Shape"
            fill="#F15953"
            fillRule="nonzero"
          />
          <path
            d="M25.000071,0.000550628241 C26.1028154,0.00422589905 26.9957004,0.767520968 27,1.7102172 L27,32.2897828 C27.000301,32.7437221 26.7918556,33.1795712 26.4196282,33.5033108 C26.0453796,33.8272496 25.5326001,34.0064672 25.000071,33.9994494 C24.4664909,34.0113027 23.9515495,33.8313295 23.5805138,33.5033108 C23.2031134,33.182502 22.9937478,32.7447288 23.0001423,32.2897828 L23.0001423,1.7102172 C22.9981397,1.2559937 23.2068395,0.819612746 23.5805138,0.496689162 C23.9515495,0.168670505 24.4664909,-0.0113027036 25.000071,0.000550628241"
            id="Path"
            fill="#F15953"
            fillRule="nonzero"
          />
          <path
            d="M32.9201103,31.0894606 C32.0187054,30.1813658 31.2969086,29.1086705 30.7932202,27.9285917 C29.2043788,24.17439 30.047155,19.8229614 32.9201103,16.9470161 C33.8396839,16.0371061 34.9208558,15.3097296 36.1072132,14.8028391 C37.3326444,14.2725446 38.6524827,13.9997786 39.9860403,14.0012167 C42.6510938,13.9588562 45.2159447,15.0242484 47.077829,16.9470161 C47.9797542,17.8646477 48.7013984,18.9459612 49.2047192,20.1339541 C49.7321362,21.3689031 50.0027629,22.6997631 49.9999403,24.0443074 C50.0036814,24.4862597 49.8313294,24.9112499 49.5214901,25.2239307 C49.2127428,25.5416225 48.7924759,25.7242041 48.3513772,25.7322766 L33.5342579,25.7322766 C33.8972004,27.1541092 34.7293719,28.4090644 35.8938777,29.2906981 C37.0692846,30.2356262 38.5346157,30.7380264 40.037758,30.7114598 C42.481419,30.7114598 44.3691148,29.9619754 45.7008454,28.4630066 C45.9755911,28.1243847 46.3794031,27.9184709 46.8127758,27.8960054 C47.2484431,27.859296 47.6801821,28.0028102 48.0087475,28.293558 C48.6838724,28.9007656 48.7445812,29.9441177 48.1445065,30.6267355 L48.1445065,30.6267355 C46.2050929,32.8751886 43.4640552,33.9961566 40.037758,33.9961566 C37.367213,34.0722548 34.7874859,33.0154271 32.926575,31.0829434 L32.9201103,31.0894606 Z M46.5024697,22.349821 C45.7466931,19.3543008 43.0714147,17.2573986 40.0054344,17.2573986 C36.9394541,17.2573986 34.2641757,19.3543008 33.5083991,22.349821 L46.5024697,22.349821 Z"
            id="Shape"
            fill="#F15953"
            fillRule="nonzero"
          />
          <path
            d="M70.8618875,4.0277524 C70.9516171,4.21945058 70.9987146,4.42795941 71,4.63920003 C70.9985554,4.86590747 70.9421728,5.08896786 70.8355804,5.28967623 L62.7264058,21.9874003 C62.3799138,22.6118705 61.716922,23 60.9967116,23 C60.2765012,23 59.6135094,22.6118705 59.2670174,21.9874003 L51.1644196,5.30919052 C51.0578272,5.10848215 51.0014446,4.88542176 51,4.65871432 C51.0012854,4.4474737 51.0483829,4.23896486 51.1381125,4.04726669 C51.3415414,3.61899291 51.7116698,3.29046392 52.1640908,3.1366 C52.3978588,3.05052185 52.6447089,3.0043319 52.8941138,3 C53.1351531,3.00263381 53.3736641,3.04893176 53.5978297,3.1366 C54.0488137,3.27474813 54.4208243,3.59315491 54.623808,4.01474288 L60.9967116,17.1153336 L67.3498849,4.01474288 C67.5579075,3.59697691 67.92798,3.28022896 68.3758632,3.1366 C68.6000288,3.04893176 68.8385397,3.00263381 69.0795791,3 C69.3375418,3.00435686 69.5930385,3.05050185 69.8359092,3.1366 C70.2915347,3.28503022 70.663575,3.61525625 70.8618875,4.04726669"
            id="Path"
            fill="#F15953"
            fillRule="nonzero"
          />
          <path
            d="M90.3196431,14.0002441 C90.7723663,13.9923306 91.2064567,14.1771279 91.5101588,14.5070606 C91.8323795,14.8153625 92.0096655,15.2413674 91.9995931,15.6831346 L91.9995931,32.3431001 C91.9959986,33.2541837 91.2470168,33.9928889 90.3196431,34 C89.8729325,33.9948574 89.4449427,33.8229558 89.1225135,33.5191742 C88.792561,33.2139938 88.6058305,32.7883047 88.6066234,32.3431001 L88.6066234,31.4854108 C84.6802277,34.9802521 78.6453119,34.8052859 74.9355349,31.0890544 C71.0214884,27.1654535 71.0214884,20.8867718 74.9355349,16.9631709 C78.6317993,13.2275381 84.6753046,13.037961 88.6066234,16.5343263 L88.6066234,15.7091252 C88.6203567,14.7810233 89.3752465,14.0279573 90.3196431,14.0002441 M81.9728054,30.6862003 C83.7404876,30.7079277 85.4365405,30.0009028 86.6488864,28.7369064 C89.2282063,26.094925 89.2282063,21.924812 86.6488864,19.2828307 C85.4465375,18.0620007 83.7986896,17.3619964 82.0697311,17.3376094 C80.3407725,17.3132225 78.6731476,17.9664622 77.4356178,19.1528778 L77.2967244,19.2828307 C74.6898533,21.913872 74.6898533,26.1058651 77.2967244,28.7369064 C78.5090703,30.0009028 80.2051232,30.7079277 81.9728054,30.6862003"
            id="Shape"
            fill="#F15953"
            fillRule="nonzero"
          />
          <path
            d="M104.316507,13.3685687 C104.771515,13.360232 105.208033,13.5504842 105.51452,13.8907115 C106.161827,14.5658229 106.161827,15.638878 105.51452,16.3139894 C105.203647,16.6479409 104.770017,16.8369345 104.316507,16.8361321 L101.668963,16.8361321 L101.668963,32.2928965 C101.665335,33.2341252 100.911798,33.9962321 99.9811537,34 C99.5418216,34.0048153 99.1200974,33.8254827 98.8162343,33.5045354 C98.4819574,33.1930349 98.2942769,32.7525667 98.2999633,32.2928965 L98.2999633,16.8093556 L95.6855137,16.8093556 C95.2305017,16.8108718 94.7957931,16.6189791 94.4875001,16.2805187 C93.8375,15.6091156 93.8375,14.535338 94.4875001,13.8639349 C94.8044216,13.5388204 95.2342016,13.3515049 95.6855137,13.3417921 L98.3065821,13.3417921 L98.3065821,1.73415708 C98.3060241,0.79965884 99.0378655,0.0328481199 99.9612971,0.00037537256 C100.424307,-0.00961665298 100.869618,0.1802712 101.185786,0.522518126 C101.505583,0.841951689 101.68251,1.27962592 101.675582,1.73415708 L101.675582,13.3685687 L104.316507,13.3685687 Z"
            id="Path"
            fill="#F15953"
            fillRule="nonzero"
          />
          <path
            d="M110.92011,31.0828199 C110.018705,30.1750779 109.296909,29.1027995 108.79322,27.9231792 C107.204379,24.1704364 108.047155,19.8206987 110.92011,16.945871 C111.839684,16.0363145 112.920856,15.3092206 114.107213,14.8025271 C115.332644,14.2724386 116.652483,13.9997787 117.98604,14.0012162 C120.651094,13.9588722 123.215945,15.0238504 125.077829,16.945871 C125.979754,17.8631459 126.701398,18.9440392 127.204719,20.1315705 C127.732136,21.3660396 128.002763,22.6963825 127.99994,24.0404043 C128.003681,24.4821849 127.831329,24.90701 127.52149,25.2195692 C127.212743,25.5371376 126.792476,25.7196482 126.351377,25.7277176 L111.48254,25.7277176 C111.85096,27.1482042 112.68472,28.4016325 113.848625,29.2847564 C115.030848,30.2137601 116.493578,30.7058753 117.992505,30.6789071 C120.436166,30.6789071 122.321707,29.9297139 123.649128,28.4313276 C123.9466,28.0936191 124.371193,27.8974101 124.819241,27.8906056 C125.481871,27.8435054 126.106513,28.2065562 126.397888,28.8081365 C126.689262,29.4097169 126.589004,30.1293256 126.144506,30.6267893 L126.144506,30.6267893 C124.205093,32.8743687 121.47052,33.9949011 118.044223,33.9949011 C115.376711,34.0826236 112.795693,33.0351363 110.93304,31.1088788 L110.92011,31.0828199 Z M124.508934,22.37915 C123.753158,19.3847938 121.077879,17.2887065 118.011899,17.2887065 C114.945919,17.2887065 112.27064,19.3847938 111.514864,22.37915 L124.508934,22.37915 Z"
            id="Shape"
            fill="#F15953"
            fillRule="nonzero"
          />
          <path
            d="M60.8404753,26.3419847 L51.1895407,38.5927707 C50.9834194,38.8505007 50.9418883,39.2016884 51.0822824,39.4997498 C51.2226765,39.7978111 51.5208627,39.991511 51.8523796,40 L71.1476204,40 C71.4791373,39.991511 71.7773235,39.7978111 71.9177176,39.4997498 C72.0581117,39.2016884 72.0165806,38.8505007 71.8104593,38.5927707 L62.232437,26.3419847 C62.0669492,26.1264872 61.8095323,26 61.5364561,26 C61.26338,26 61.005963,26.1264872 60.8404753,26.3419847 Z"
            id="Path"
            fill="#5A5C5D"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgElevateIcon;
