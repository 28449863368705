import * as React from 'react';

const SvgAssets = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="26px" height="31px" viewBox="0 0 26 31" version="1.1">
    <title>Assets</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Side-Menu-Icons"
        transform="translate(-450.000000, -660.000000)"
        stroke="#FFFFFF"
      >
        <g id="Account-Icon" transform="translate(451.000000, 661.000000)">
          <line
            x1="4.08333333"
            y1="8.75076923"
            x2="16.8"
            y2="8.75076923"
            id="Line-3"
          />
          <line
            x1="4.04166667"
            y1="13.5507692"
            x2="16.8"
            y2="13.5507692"
            id="Line-3"
          />
          <line
            x1="4.04166667"
            y1="18.3507692"
            x2="16.8"
            y2="18.3507692"
            id="Line-3"
          />
          <path
            d="M0,3.92 L0,26.8 C1.3527075e-16,27.9045695 0.8954305,28.8 2,28.8 L19.12,28.8 C20.2245695,28.8 21.12,27.9045695 21.12,26.8 L21.12,3.92 C21.12,2.8154305 20.2245695,1.92 19.12,1.92 L2,1.92 C0.8954305,1.92 -1.3527075e-16,2.8154305 0,3.92 Z"
            id="Path"
          />
          <path
            d="M21.2187945,26.9747085 L22.0680666,26.9457882 C23.1455175,26.9090977 24,26.0250222 24,24.9469468 L24,2 C24,0.8954305 23.1045695,-5.37915334e-14 22,-5.45696821e-14 L11.686955,-5.45696821e-14 L11.686955,-5.45696821e-14 L10.1725747,-5.45696821e-14 C9.45022596,-5.3770855e-14 8.86464658,0.585579374 8.86464658,1.30792814 C8.86464658,1.5213007 8.91684893,1.73143327 9.016701,1.92 L9.016701,1.92 L9.016701,1.92"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgAssets;
