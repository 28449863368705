import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { parseISO, format } from 'date-fns';
import { Modal, Checkbox, List } from 'antd';
import React, { FC, useContext } from 'react';
import { emDash } from 'src/common/utils';
import {
  Task,
  TaskStatusEnum,
  TaskUpdateBodyStatusEnum
} from '@elm-street-technology/crm-axios-client';
import { TasksDispatchContext } from '../Tasks.context';
import { deleteClientTask, updateClientTask } from '../actions';

interface Props {
  task: Task;
}

export const TaskListItem: FC<Props> = ({ task }) => {
  const dispatch = useContext(TasksDispatchContext);

  const handleDeleteClick = e => {
    Modal.confirm({
      title: 'Are you sure you want to delete this task?',
      icon: <ExclamationCircleOutlined />,
      cancelText: 'No',
      okButtonProps: {
        danger: true
      },
      okText: 'Yes - Delete',
      onOk: () => deleteClientTask(dispatch, task.clientId, task.id)
    });
  };

  const handleEditClick = e => dispatch({ type: 'ShowEdit', payload: task });

  const handleCheckboxClick = () =>
    updateClientTask(dispatch, task.clientId, task.id, {
      status:
        task.status === TaskStatusEnum.DONE
          ? TaskUpdateBodyStatusEnum.NEW
          : TaskUpdateBodyStatusEnum.DONE
    });

  const dueDate = format(parseISO(task.dueDateIsoStr), 'MM/dd/yyyy');

  return (
    <List.Item
      actions={[
        <EditFilled onClick={handleEditClick} />,
        <DeleteFilled onClick={handleDeleteClick} />
      ]}
    >
      <List.Item.Meta
        avatar={
          <Checkbox
            onClick={handleCheckboxClick}
            checked={task.status === TaskStatusEnum.DONE}
          />
        }
        title={<span style={{ fontSize: 15 }}>{task.title}</span>}
        description={
          <div style={{ fontSize: 11 }}>
            <div style={{ color: '#082746' }}>{task.description}</div>
            <div>Due on {dueDate}</div>
            <div>Updated by {task.updatedByDisplayName || emDash}</div>
            <div>Assigned to {task.assignedToDisplayName || emDash}</div>
          </div>
        }
      />
    </List.Item>
  );
};
