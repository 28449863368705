/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useContext, FC } from 'react';
import {
  ClientAbility,
  Client,
  ClientAssignment,
  ClientUpdateBodyStatusEnum
} from '@elm-street-technology/crm-axios-client';
import {
  AffiliateDataSection,
  ProfileSection,
  ArchiveSection,
  AssignedToSection,
  ContributorHistorySection,
  ContributorsSection,
  PermissionsSection,
  BuyingSection,
  SellingSection,
  CurrentResidentsSection,
  AssignmentHistorySection,
  SourceTagsSection
} from './components';
import {
  listClientContributors,
  showClientAssignment,
  listClientPermissions,
  listClientContributorHistory,
  getClientAffiliateData,
  updateClient
} from '../../actions';
import {
  ClientRecordDrawerStateContext,
  ClientRecordDrawerDispatchContext
} from '../../ClientRecordDrawer.context';

interface Props {
  client: Client;
  onUpdateSuccess: (client: Client) => void;
  onAssignmentSuccess: (clientAssignment: ClientAssignment) => void;
}

export const ProfileTab: FC<Props> = ({
  client,
  onUpdateSuccess,
  onAssignmentSuccess
}) => {
  const dispatch = useContext(ClientRecordDrawerDispatchContext);
  const {
    abilities: { data: clientAbilities },
    assignment: { data: assignment },
    affiliateData: { data: affiliateData },
    client: { updating },
    contributors: { data: contributors },
    contributorHistory: { data: contributorHistory },
    permissions: { data: permissions }
  } = useContext(ClientRecordDrawerStateContext);

  useEffect(() => {
    listClientContributors(dispatch, client.id);
    showClientAssignment(dispatch, client.id);
    listClientPermissions(dispatch, client.id);
    listClientContributorHistory(dispatch, client.id);
    getClientAffiliateData(dispatch, client.id);
  }, [clientAbilities, client.id]);

  useEffect(() => {
    listClientPermissions(dispatch, client.id);
    listClientContributorHistory(dispatch, client.id);
  }, [contributors]);

  const handleUnarchiveClick = (client: Client) =>
    updateClient(
      dispatch,
      client.id,
      {
        status: client.isContact
          ? ClientUpdateBodyStatusEnum.Marketing
          : ClientUpdateBodyStatusEnum.New
      },
      onUpdateSuccess
    );

  const handleArchiveClick = (client: Client) =>
    updateClient(
      dispatch,
      client.id,
      {
        status: ClientUpdateBodyStatusEnum.Archived
      },
      onUpdateSuccess
    );

  const handleTagsSelectChange = tags =>
    updateClient(
      dispatch,
      client.id,
      {
        tags
      },
      onUpdateSuccess
    );

  return (
    <>
      <ProfileSection client={client} />

      <SourceTagsSection
        client={client}
        onTagSelectChange={handleTagsSelectChange}
      />

      {client?.sellerFields?.current_residents &&
        client?.sellerFields?.current_residents.length > 0 && (
          <CurrentResidentsSection
            currentResidents={client.sellerFields.current_residents}
          />
        )}

      {(client.type === 'buyer' || client.type === 'buyer/seller') &&
        client.buyerFields && (
          <BuyingSection buyerFields={client.buyerFields} />
        )}

      {(client.type === 'seller' || client.type === 'buyer/seller') &&
        client.sellerFields && (
          <SellingSection sellerFields={client.sellerFields} />
        )}

      {affiliateData && Object.keys(affiliateData).length > 0 && (
        <AffiliateDataSection affiliateData={affiliateData} />
      )}

      {assignment && (
        <AssignedToSection
          clientId={client.id}
          assignment={assignment}
          onAssignmentSuccess={onAssignmentSuccess}
        />
      )}

      {assignment?.history && assignment.history.length && (
        <AssignmentHistorySection assignmentHistory={assignment.history} />
      )}

      {permissions.length > 0 && (
        <PermissionsSection permissions={permissions} />
      )}

      <ContributorsSection clientId={client.id} />

      {permissions.length > 0 && (
        <ContributorHistorySection contributorHistory={contributorHistory} />
      )}

      {clientAbilities.includes(ClientAbility.Manageclient) && (
        <ArchiveSection
          onUnarchive={handleUnarchiveClick}
          onArchive={handleArchiveClick}
          updating={updating}
          client={client}
        />
      )}
    </>
  );
};
