import React, { useMemo } from 'react';
import { Modal, Table, Grid } from 'antd';
import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { ClientContributor } from '@elm-street-technology/crm-axios-client';

interface Props {
  loading: boolean;
  contributors: ClientContributor[];
  onDeleteContributor: (contributorUserId: number) => void;
  canManageContributors: boolean;
}

export const ContributorsTable: React.FC<Props> = ({
  loading,
  contributors,
  onDeleteContributor,
  canManageContributors
}) => {
  const { xs } = Grid.useBreakpoint();

  const columns = useMemo(() => {
    const cols = [
      {
        title: 'Full Name',
        render: contributor =>
          `${contributor?.firstName} ${contributor?.lastName}`
      },
      { title: 'Email', dataIndex: 'email' },
      { title: 'Phone', dataIndex: 'phone' },
      {
        title: 'Delete',
        render: contributor => (
          <DeleteFilled
            onClick={e =>
              Modal.confirm({
                title: 'Are you sure you want to remove this contributor?',
                icon: <ExclamationCircleOutlined />,
                cancelText: 'No',
                okButtonProps: {
                  danger: true
                },
                okText: 'Yes - Delete',
                onOk: () => onDeleteContributor(contributor.userId)
              })
            }
          />
        )
      }
    ];

    if (xs) {
      cols.splice(1, 2);
    }

    if (!canManageContributors) {
      cols.pop();
    }

    return cols;
  }, [xs, canManageContributors, onDeleteContributor]);

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={contributors}
      pagination={false}
    />
  );
};
