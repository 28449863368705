import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React, { FC, useCallback, useEffect, useReducer, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { TABLET } from 'src/common/constants';
import { useUserAccount } from 'src/common/hooks';
import { isSubMenuProps, makeMenuData, makeMenuItems } from './Nav.utils';
import { CRMMenu, CRMSider } from './Nav.styled';
import { initializeNavReducer } from './Nav.reducer';

export const Nav: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: TABLET });
  const navigate = useNavigate();

  const { user } = useUserAccount();
  const data = useMemo(() => makeMenuData(navigate, user.menu), [
    navigate,
    user
  ]);

  const initialOpenKeys = useMemo(() => {
    const subMenuKeys = data.filter(i => isSubMenuProps(i)).map(i => i.key);

    if (data.some(i => !i.href?.startsWith('/office'))) {
      return [...subMenuKeys, 'offices'];
    }

    return subMenuKeys;
  }, [data]);

  const [{ collapsed, collapsedWidth, openKeys }, dispatch] = useReducer(
    initializeNavReducer(initialOpenKeys),
    {
      collapsedWidth: isMobile ? '0' : '80',
      collapsed: isMobile,
      openKeys: isMobile ? undefined : initialOpenKeys
    }
  );

  useEffect(() => {
    dispatch({ type: isMobile ? 'MobileBreakpoint' : 'DesktopBreakpoint' });
  }, [isMobile]);

  const handleCollapse = useCallback(
    () => dispatch({ type: isMobile ? 'MobileCollapse' : 'DesktopCollapse' }),
    [isMobile]
  );

  return (
    <CRMSider
      collapsed={collapsed}
      collapsedWidth={collapsedWidth}
      breakpoint="lg"
      width="220"
      collapsible
      zeroWidthTriggerStyle={{ backgroundColor: '#f15953' }}
      onCollapse={handleCollapse}
    >
      <CRMMenu mode="inline" {...(openKeys ? { openKeys } : undefined)}>
        <CRMMenu.Item key="1" className="menu-items" onClick={handleCollapse}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </CRMMenu.Item>
        {makeMenuItems(data)}
      </CRMMenu>
    </CRMSider>
  );
};
