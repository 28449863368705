import React, { FC } from 'react';
import { Client } from '@elm-street-technology/crm-axios-client';
import { CommunicationSection } from './CommunicationSection';
import { SimilarListingsSection } from './SimilarListingsSection';

interface Props {
  client: Client;
}

export const SellerActivityTab: FC<Props> = ({ client }) => (
  <>
    <SimilarListingsSection client={client} />
    <CommunicationSection client={client} />
  </>
);
