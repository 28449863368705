import React from 'react';

const OfficeIcon = () => (
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
    <title>Office</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Side-Menu-Icons"
        transform="translate(-236.000000, -589.000000)"
        stroke="#FFFFFF"
      >
        <g id="Office" transform="translate(237.000000, 590.000000)">
          <line x1="5.74698129" y1="4.2" x2="5.74698129" y2="6.6" id="Line-3" />
          <line x1="13.05" y1="6.6" x2="13.05" y2="4.2" id="Line-3" />
          <line x1="13.05" y1="12.6" x2="13.05" y2="10.2" id="Line-3" />
          <line
            x1="20.5071428"
            y1="11.4"
            x2="20.5071428"
            y2="10.2"
            id="Line-3"
          />
          <line
            x1="20.5071428"
            y1="17.4"
            x2="20.5071428"
            y2="16.2"
            id="Line-3"
          />
          <polygon
            id="Line-3"
            points="10.7184099 24 10.7184099 20.4 8.23269558 20.4 8.23269558 24"
          />
          <line
            x1="5.59285715"
            y1="10.2"
            x2="5.59285715"
            y2="12.6"
            id="Line-3"
          />
          <path
            d="M0,1.77777778 L0,22.2222222 C0,23.2040618 0.795938222,24 1.77777778,24 L16.8650794,24 C17.8469189,24 18.6428572,23.2040618 18.6428572,22.2222222 L18.6428572,1.77777778 C18.6428572,0.795938222 17.8469189,0 16.8650794,0 L1.77777778,0 C0.795938222,0 0,0.795938222 0,1.77777778 Z"
            id="Path"
          />
          <path
            d="M16.7221218,24 L21.8365079,24 C22.8183475,24 23.6142857,23.2040618 23.6142857,22.2222222 L23.6142857,6.57777778 C23.6142857,5.59593822 22.8183475,4.8 21.8365079,4.8 L19.6036723,4.8 L19.6036723,4.8 C19.0730287,4.8 18.6428572,5.23017159 18.6428572,5.76081515 L18.6428572,6.60634209 L18.6428572,6.60634209"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default OfficeIcon;
