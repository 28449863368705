import React, { FC, useRef, useState, RefObject, useContext } from 'react';
import { Client, ClientAbility } from '@elm-street-technology/crm-axios-client';
import { CommunicationSection } from '../CommunicationSection';
import {
  SavedSearchesSection,
  ActivityListingsSection,
  ActivityOverviewSection
} from './components';
import { ClientRecordDrawerStateContext } from '../../ClientRecordDrawer.context';

interface Props {
  client: Client;
}

export interface RefList {
  SEARCHED: RefObject<HTMLDivElement>;
  FAVORITED: RefObject<HTMLDivElement>;
  VIEWED: RefObject<HTMLDivElement>;
  MATCHED: RefObject<HTMLDivElement>;
}

export const ActivityTab: FC<Props> = ({ client }) => {
  const {
    abilities: { data: clientAbilities }
  } = useContext(ClientRecordDrawerStateContext);

  const sectionRefs: RefList = {
    SEARCHED: useRef<HTMLDivElement>(null),
    FAVORITED: useRef<HTMLDivElement>(null),
    VIEWED: useRef<HTMLDivElement>(null),
    MATCHED: useRef<HTMLDivElement>(null)
  };

  const handleClick = (id: keyof RefList) => {
    if (!sectionRefs[id]) return;
    sectionRefs[id].current!.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const [favorited, setFavorited] = useState(0);
  const [viewed, setViewed] = useState(0);

  return (
    <>
      <ActivityOverviewSection
        client={client}
        handleClick={handleClick}
        favorited={favorited}
        viewed={viewed}
      />
      <div ref={sectionRefs.SEARCHED} />
      <SavedSearchesSection
        client={client}
        canManage={clientAbilities.includes(ClientAbility.Manageclient)}
      />
      <div ref={sectionRefs.FAVORITED} />
      <ActivityListingsSection
        action="favorited"
        clientId={client.id}
        setDashboardCount={setFavorited}
        handleClick={handleClick}
      />
      <div ref={sectionRefs.VIEWED} />
      <ActivityListingsSection
        action="viewed"
        clientId={client.id}
        setDashboardCount={setViewed}
        handleClick={handleClick}
      />

      <div ref={sectionRefs.MATCHED} />
      <ActivityListingsSection
        action="matched"
        clientId={client.id}
        handleClick={handleClick}
      />
      <CommunicationSection client={client} />
    </>
  );
};
