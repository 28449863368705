export const PRIMARY = '#082746';
export const FONT_FAMILY = `Roboto, sans-serif`;

export const LIGHT_BLUE = '#d9eefc';
export const LIGHT_MAGENTA = '#f1eaff';
export const LIGHT_GREEN = '#f4fff5';
export const LIGHT_YELLOW = '#fff8e5';
export const LIGHT_RED = '#ffeded';
export const BORDER_COLOR = 'rgb(221, 226, 231)';
export const WHITE = '#ffffff';
export const GREY = '#a7a7a7';
export const LIGHT_BLUE_GREY = '#b4c4c8';
export const DARK_GREY = '#212121';
export const TEAL = '#98d5fd';
export const GREEN = '#6dbc6d';
export const BLUE = '#4ea5f2';
export const ORANGE = '#facb3e';
export const ORANGE_2 = '#ff7043';
export const LIGHT_ORANGE = '#ffeadb';
export const RED = '#f33b3b';
export const BLACK = '#000000';
export const BROWN = '#b17e5c';
export const PINK = '#d958c2';
export const PURPLE = '#674bcd';

// Basic Styles
const DEFAULT_THEME = {
  fontFamily: FONT_FAMILY,
  primaryBlue: '#0091ff',
  secondaryBlue: '#082746',
  defaultBackGround: '#F3F5F7',
  defaultNavBar: '#F15953',
  defaultNotifications: '#0A2847',
  whiteBackGround: '#FFFFFF',
  defaultBackgroundColor: WHITE,
  primaryTextColor: DARK_GREY,
  border: '#F5F5F5',
  highlightColor: '#c4e0fe',
  input: {
    borderColor: '#cccfd4',
    focusBorderColor: '#0270c9',
    focusShadowColor: '#c4e0fe',
    errorBorderColor: '#ff3c41',
    errorTextColor: '#ff3c41',
    errorBackgroundColor: '#feeeee',
    placeholderColor: '#878787'
  },
  headerButtonBackground: '#F2F5F7',
  badgeBackground: '#0EBEFF',
  lightBlue: LIGHT_BLUE,
  lightMagneta: LIGHT_MAGENTA,
  lightGreen: LIGHT_GREEN,
  lightYellow: LIGHT_YELLOW,
  lightRed: LIGHT_RED,
  lightBlueGrey: LIGHT_BLUE_GREY,
  subtleTextColor: GREY,
  teal: TEAL,
  buttons: {
    defaultTextColor: WHITE,
    outlinedTextColor: GREY
  }
};

export default DEFAULT_THEME;
