import { Input, DatePicker, Form, Button, Row, Col } from 'antd';
import moment from 'moment';
import React, { FC, useState, useContext } from 'react';
import {
  TaskCreateBody,
  TaskUpdateBody
} from '@elm-street-technology/crm-axios-client';
import { Flex, Spin, FloatLabel } from 'src/crm/components';
import { TasksStateContext, TasksDispatchContext } from '../Tasks.context';
import { createClientTask, updateClientTask } from '../actions';

interface Props {
  clientId: number;
}

export const TaskEditor: FC<Props> = ({ clientId }) => {
  const { editTask, loading } = useContext(TasksStateContext);
  const dispatch = useContext(TasksDispatchContext);

  const [form] = Form.useForm();

  const [taskValues, setTaskValues] = useState({
    title: editTask?.title,
    description: editTask?.description,
    dueDate: moment(editTask?.dueDateIsoStr)
  });

  const handleCancelClick = () => dispatch({ type: 'ShowList' });

  const handleFinish = formValues => {
    const taskBody = {
      name: formValues.title,
      dueDate: formValues?.dueDate?.toISOString(),
      description: formValues.description
    };

    if (editTask) {
      updateClientTask(
        dispatch,
        clientId,
        editTask.id,
        taskBody as TaskUpdateBody
      );
    } else {
      createClientTask(dispatch, clientId, taskBody as TaskCreateBody);
    }
  };

  const buttons = (
    <Row gutter={[16, 16]} justify="end">
      <Col>
        <Button onClick={handleCancelClick} size="large">
          Cancel
        </Button>
      </Col>
      <Col>
        <Form.Item>
          <Button
            loading={loading}
            disabled={loading}
            htmlType="submit"
            type="primary"
            size="large"
          >
            Save Changes
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );

  return (
    <Flex alignContent="center" justifyContent="center" flexDirection="column">
      <Spin centered spinning={loading} size="large" />
      {!loading && (
        <Form
          initialValues={taskValues}
          form={form}
          noValidate
          validateMessages={{
            required: 'This field is required!'
          }}
          onFinish={handleFinish}
          onValuesChange={changedValues =>
            setTaskValues({
              ...taskValues,
              ...(changedValues as typeof taskValues)
            })
          }
        >
          <FloatLabel value={taskValues.title} name="title" label="Title">
            <Form.Item name="title" rules={[{ required: true }]}>
              <Input maxLength={200} />
            </Form.Item>
          </FloatLabel>
          <Form.Item name="dueDate">
            <DatePicker size="large" format="ddd, MMMM Do YYYY" allowClear />
          </Form.Item>

          <FloatLabel
            value={taskValues.description}
            name="description"
            label="Description"
          >
            <Form.Item name="description" rules={[{ required: true }]}>
              <Input.TextArea maxLength={1000} />
            </Form.Item>
          </FloatLabel>
          {buttons}
        </Form>
      )}
    </Flex>
  );
};
