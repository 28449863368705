import * as React from 'react';

const SvgDashboard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="25px" height="20px" viewBox="0 0 25 20" version="1.1">
    <title>Dashboard</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Icons/Side-Menu/Minimized-View/Minimized-UnSelected-Dashboard-Icon"
        transform="translate(-15.000000, -15.000000)"
        stroke="#FFFFFF"
      >
        <g
          id="Icons/Side-Menu/Dashboard-Icon"
          transform="translate(16.000000, 16.000000)"
        >
          <g id="Group-4">
            <g id="Group-2">
              <g id="Account-Icon">
                <path
                  d="M0,2 L0,5 C1.3527075e-16,6.1045695 0.8954305,7 2,7 L8,7 C9.1045695,7 10,6.1045695 10,5 L10,2 C10,0.8954305 9.1045695,-2.02906125e-16 8,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                  id="Path"
                />
              </g>
              <g id="Account-Icon">
                <path
                  d="M0,2 L0,5 C1.3527075e-16,6.1045695 0.8954305,7 2,7 L8,7 C9.1045695,7 10,6.1045695 10,5 L10,2 C10,0.8954305 9.1045695,-2.02906125e-16 8,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                  id="Path"
                />
              </g>
            </g>
            <g id="Group-2" transform="translate(13.000000, 0.000000)">
              <g id="Account-Icon">
                <path
                  d="M0,2 L0,5 C1.3527075e-16,6.1045695 0.8954305,7 2,7 L8,7 C9.1045695,7 10,6.1045695 10,5 L10,2 C10,0.8954305 9.1045695,-2.02906125e-16 8,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                  id="Path"
                />
              </g>
              <g id="Account-Icon" fill="#FFFFFF">
                <path
                  d="M0,2 L0,5 C1.3527075e-16,6.1045695 0.8954305,7 2,7 L8,7 C9.1045695,7 10,6.1045695 10,5 L10,2 C10,0.8954305 9.1045695,-2.02906125e-16 8,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                  id="Path"
                />
              </g>
            </g>
            <g id="Group-2" transform="translate(13.000000, 11.000000)">
              <g id="Account-Icon">
                <path
                  d="M0,2 L0,5 C1.3527075e-16,6.1045695 0.8954305,7 2,7 L8,7 C9.1045695,7 10,6.1045695 10,5 L10,2 C10,0.8954305 9.1045695,-2.02906125e-16 8,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                  id="Path"
                />
              </g>
              <g id="Account-Icon">
                <path
                  d="M0,2 L0,5 C1.3527075e-16,6.1045695 0.8954305,7 2,7 L8,7 C9.1045695,7 10,6.1045695 10,5 L10,2 C10,0.8954305 9.1045695,-2.02906125e-16 8,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                  id="Path"
                />
              </g>
            </g>
            <g id="Group-2" transform="translate(0.000000, 11.000000)">
              <g id="Account-Icon">
                <path
                  d="M0,2 L0,5 C1.3527075e-16,6.1045695 0.8954305,7 2,7 L8,7 C9.1045695,7 10,6.1045695 10,5 L10,2 C10,0.8954305 9.1045695,-2.02906125e-16 8,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                  id="Path"
                />
              </g>
              <g id="Account-Icon" fill="#FFFFFF">
                <path
                  d="M0,2 L0,5 C1.3527075e-16,6.1045695 0.8954305,7 2,7 L8,7 C9.1045695,7 10,6.1045695 10,5 L10,2 C10,0.8954305 9.1045695,-2.02906125e-16 8,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgDashboard;
