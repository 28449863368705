import React, { CSSProperties, useEffect, useState, FC } from 'react';
import MomentUtils from '@date-io/moment';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withProfiler } from '@sentry/react';
import { Layout, Grid, Spin } from 'antd';
import { UserRolesEnum, User } from '@elm-street-technology/crm-axios-client';
import { ThemeProvider } from 'styled-components';
import { SocketIOProvider } from 'use-socketio';

import {
  FeatureTogglesProvider,
  UserAccountContext,
  UserAccountDispatchContext
} from 'src/common/contexts';
import {
  identifyPendoUser,
  initializeSentryUser,
  userApi
} from 'src/common/services';
import GlobalStyles from 'src/GlobalStyle';
import DEFAULT_THEME from 'src/themes';
import { LoadingIndicator } from 'src/crm/components/LoadingIndicator';
import { routes } from 'src/routes';
import {
  CenteredContainer,
  GlobalDrawerSwitch,
  GlobalPhoneNumberModal
} from 'src/common/components';
import { GlobalDrawerProvider } from 'src/common/hooks';
import { ProfileProvider } from './crm/pages/Profile/Profile.context';

import './App.css';
import { Header, Nav } from './crm/components';
import { muiTheme, CRM_HOST, SOCKET_IO_URL } from './common/constants';

const style = {
  content: (md?: boolean) => {
    const margin = md ? '64px 1rem 0 1rem' : '64px 0';

    return {
      height: 'calc(100vh - 64px)',
      top: 64,
      paddingBottom: 64,
      paddingRight: 0,
      overflow: 'auto',
      margin
    } as CSSProperties;
  }
};

declare global {
  interface Window {
    // Google tag manager's global variable
    dataLayer: Array<{}>;
    // uploadcare's global variable
    uploadcare: Record<'openDialog', Function>;
  }
}

const App: FC = () => {
  const { md } = Grid.useBreakpoint();

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    userApi
      .getMe()
      .then(({ data: user }) => {
        if (user?.roles.includes(UserRolesEnum.SuperAdmin)) {
          window.location.replace(`${CRM_HOST}/admin/users`);
        }

        identifyPendoUser(user);
        initializeSentryUser(user);
        sessionStorage.setItem('mlsAccessToken', user.mlsAccessToken || '');
        setUser(user);
      })
      .catch(() => {
        window.location.href = `${CRM_HOST}/login`;
      });
  }, []);

  if (!user || user.roles.includes(UserRolesEnum.SuperAdmin)) {
    return (
      <CenteredContainer>
        <Spin indicator={<LoadingIndicator />} size="large" />
      </CenteredContainer>
    );
  }

  const layout = (
    <Layout>
      <Header />
      <Layout>
        <Nav />
        <Layout.Content style={style.content(md)}>
          {routes}
          <GlobalDrawerSwitch />
          <ProfileProvider>
            <GlobalPhoneNumberModal userId={user.id} />
          </ProfileProvider>
        </Layout.Content>
      </Layout>
    </Layout>
  );

  return (
    <BrowserRouter>
      <ThemeProvider theme={DEFAULT_THEME}>
        <SocketIOProvider url={SOCKET_IO_URL}>
          <GlobalStyles />
          <MuiThemeProvider theme={muiTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <UserAccountContext.Provider value={user}>
                <UserAccountDispatchContext.Provider value={setUser}>
                  <FeatureTogglesProvider user={user}>
                    <GlobalDrawerProvider>{layout}</GlobalDrawerProvider>
                  </FeatureTogglesProvider>
                </UserAccountDispatchContext.Provider>
              </UserAccountContext.Provider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </SocketIOProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default withProfiler(App);
