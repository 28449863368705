import * as React from 'react';

const CampaignIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="25px" height="28px" viewBox="0 0 25 28" version="1.1">
    <title>Campaigns</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Side-Menu-Icons" transform="translate(-384.000000, -589.000000)">
        <g
          id="Task/Desktop/Email2"
          transform="translate(385.000000, 590.000000)"
        >
          <g id="Task/Desktop/Email" fillRule="nonzero" stroke="#FFFFFF">
            <g id="wedding-invitation">
              <path
                d="M23.0092048,11.9317808 L23.0092048,23.6883877 C23.0095414,24.0864695 22.9026853,24.476964 22.7002077,24.8178879 L17.2640222,19.2151524 C17.1157218,19.0621412 16.8727987,19.0601179 16.7217519,19.2105999 C16.5707051,19.3608291 16.5687078,19.6069114 16.7172579,19.7599225 L22.1946379,25.405147 C21.8096559,25.7192624 21.3303009,25.8902302 20.8359661,25.8897549 L2.94004561,25.8897549 C2.44471209,25.8902302 1.96435843,25.7182507 1.57912675,25.4028708 L7.02629744,19.7589108 C7.17434822,19.6056468 7.1716019,19.3595645 7.0203055,19.2095883 C6.8687594,19.0596121 6.62608592,19.0623941 6.47803514,19.2159111 L1.07380668,24.8151059 C0.872577468,24.4746877 0.766470255,24.085205 0.766804535,23.6883877 L0.766804535,12.1516033 L10.1563359,20.2060573 C11.1567398,21.0687365 12.6275108,21.0682306 13.6274154,20.2045399 L23.0092048,11.9317808 Z"
                id="Shape"
              />
              <path
                d="M1.35400313,0.184915513 C14.5442862,0.184915513 21.5170269,0.184915513 22.2722253,0.184915513 C23.4050229,0.184915513 23.1902769,1.18316843 23.1902769,1.4022116 C23.246902,1.87988549 19.9541555,5.22198679 13.3120375,11.4285155 C12.5979983,12.0999486 11.3664576,12.0991231 10.651919,11.4285155 C3.71777874,5.37979376 0.312399575,2.03769246 0.435781515,1.4022116 C0.620854424,0.4489903 0.302917429,0.186566614 1.35400313,0.184915513 Z"
                id="Path"
                transform="translate(11.819526, 6.058348) scale(1, -1) translate(-11.819526, -6.058348) "
              />
            </g>
          </g>
          <g
            id="Drip"
            transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) translate(8.000000, 6.666667)"
            strokeWidth="0.5"
          >
            <path
              d="M4,10.6666667 C4,10.6666667 8,6.11915148 8,3.94202899 C8,1.76490649 6.209139,0 4,0 C1.790861,0 0,1.76490649 0,3.94202899 C0,6.11915148 4,10.6666667 4,10.6666667 Z"
              id="Oval"
              stroke="#FFFFFF"
              fill="#FFFFFF"
            />
            <path
              d="M6.3500928,5.33333333 C6.3500928,5.33333333 6.65747573,4.81059542 6.5729024,3.71773737 C6.5518152,3.44524812 6.39771282,2.95218305 6.11059528,2.23854215"
              id="Path-4"
              stroke="#082746"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CampaignIcon;
