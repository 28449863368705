import React from 'react';

const MyTeamIcon = () => (
  <svg width="26px" height="15px" viewBox="0 0 26 15" version="1.1">
    <title>My Team</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Side-Menu-Icons"
        transform="translate(-271.000000, -579.000000)"
        stroke="#FFFFFF"
      >
        <g id="Group-5" transform="translate(272.000000, 580.000000)">
          <g id="Group-4" transform="translate(15.652174, 1.000000)">
            <path
              d="M4.38488964,4.838356 C5.29449063,4.838356 6.1203227,5.21839737 6.71815681,5.83897157 C7.30302392,6.44608554 7.66869672,7.28438866 7.67129985,8.21515988 L7.67129985,10.2388358 L1.99934223,10.2388358 C1.79928472,9.27950794 1.73870725,9.0457197 1.44926462,8.43408166 C1.28868695,8.09475564 0.744552116,7.21033487 0.591666056,6.96601536 C0.052168951,5.34357228 2.11745889,4.83978021 3.01976211,4.838356 L4.38488964,4.838356 Z"
              id="Path"
              strokeLinejoin="round"
            />
            <ellipse
              id="Oval"
              cx="3.73478261"
              cy="2.0625"
              rx="2.08695652"
              ry="2.0625"
            />
          </g>
          <g id="Group-3" transform="translate(6.260870, 0.000000)">
            <path
              d="M6.66805629,6.21168663 C7.81344229,6.21168663 8.84961292,6.69432921 9.6011308,7.47054223 C10.3661076,8.2606564 10.8371812,9.35373572 10.840569,10.562685 L10.840569,12.6564767 L0.653256287,12.6564767 L0.638574356,10.6637205 C0.61408158,9.41787745 1.08887366,8.28563957 1.87463043,7.47297407 C2.62497966,6.69692867 3.65988994,6.21348619 4.80546999,6.21168663 L6.66805629,6.21168663 Z"
              id="Path"
              strokeLinejoin="round"
            />
            <ellipse id="Oval" cx="5.73913043" cy="2.8" rx="3" ry="2.8" />
          </g>
          <g id="Group" transform="translate(0.000000, 1.000000)">
            <ellipse
              id="Oval"
              cx="4.31304348"
              cy="2.0625"
              rx="2.08695652"
              ry="2.0625"
            />
            <path
              d="M5.50855226,4.70453698 C8.11985971,5.29386525 7.96936393,6.25531118 7.82853897,6.66222948 C7.64315635,6.99986265 6.94812016,8.54180177 6.89853999,8.91359572 L6.50256143,10.2388358 L0.696716989,10.2388358 L0.696716989,8.17426572 C0.672596582,7.2057919 1.05672136,6.32736231 1.68843608,5.6970042 C2.30229998,5.08445857 3.14976785,4.70595643 4.08649315,4.70453698 L5.50855226,4.70453698 Z"
              id="Path"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MyTeamIcon;
