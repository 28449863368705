import * as React from 'react';

const SvgMySite = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1">
    <title>My Site</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Side-Menu-Icons" transform="translate(-272.000000, -618.000000)">
        <g id="Group-14" transform="translate(272.000000, 618.000000)">
          <g id="Group-2">
            <g
              id="Account-Icon"
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line
                x1="0.729199788"
                y1="6.64960819"
                x2="10.9379968"
                y2="0.738845354"
                id="Line-3"
              />
              <line
                x1="10.9379968"
                y1="0.738845354"
                x2="21.1467939"
                y2="6.64960819"
                id="Line-4"
              />
              <path
                d="M3.64599894,9.23744571 L3.64599894,17.1106325 C3.64599894,18.215202 4.54142944,19.1106325 5.64599894,19.1106325 L16.2299947,19.1106325 C17.3345642,19.1106325 18.2299947,18.215202 18.2299947,17.1106325 L18.2299947,9.23744571 L18.2299947,9.23744571"
                id="Path"
              />
            </g>
            <g
              id="Group"
              transform="translate(14.604039, 8.170063)"
              fill="#FFFFFF"
            >
              <g
                id="Group"
                transform="translate(6.594595, 6.611227) rotate(-59.000000) translate(-6.594595, -6.611227) translate(1.945946, 1.945946)"
              >
                <polygon
                  id="Triangle"
                  transform="translate(1.778477, 7.522725) rotate(-135.000000) translate(-1.778477, -7.522725) "
                  points="1.77545288 6.67466461 3.27905439 8.37078594 0.277899385 8.36542673"
                />
                <polygon
                  id="Rectangle"
                  transform="translate(4.692411, 4.603170) rotate(-45.000000) translate(-4.692411, -4.603170) "
                  points="1.41401349 3.12726773 7.97610005 3.11554972 7.97080805 6.07907269 1.40872149 6.0907907"
                />
                <path
                  d="M6.97448497,0.215838216 L7.24099003,0.215362314 C7.7932739,0.214376093 8.24178792,0.661291139 8.24277414,1.21357501 C8.24277627,1.21476548 8.24277627,1.21595596 8.24277414,1.21714643 L8.24105358,2.18065983 C8.24006985,2.73154949 7.79373055,3.17788879 7.24284089,3.17887252 L6.96919297,3.17936118 L6.96919297,3.17936118 L6.97448497,0.215838216 Z"
                  id="Rectangle"
                  transform="translate(7.606880, 1.696466) rotate(-45.000000) translate(-7.606880, -1.696466) "
                />
              </g>
            </g>
          </g>
          <path
            d="M10.814895,8.61417486 C11.2182117,8.55655818 11.6448556,8.66223163 11.987153,8.94657712 L11.987153,8.94657712 L16.0648649,14.3162162 C16.313393,14.6475871 16.4040784,15.048323 16.3497891,15.4283483 C16.2954998,15.8083735 16.0962357,16.1676881 15.7648649,16.4162162 C15.5052206,16.6109494 15.1894202,16.7162162 14.8648649,16.7162162 L14.8648649,16.7162162 L7.18918919,16.7162162 C6.77497563,16.7162162 6.39997563,16.548323 6.12852902,16.2768764 C5.85708241,16.0054298 5.68918919,15.6304298 5.68918919,15.2162162 C5.68918919,14.8916609 5.794456,14.5758605 5.98918919,14.3162162 L5.98918919,14.3162162 L9.82702703,9.1990991 C10.0755552,8.86772825 10.4348697,8.66846418 10.814895,8.61417486 Z"
            id="Triangle"
            stroke="#FFFFFF"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgMySite;
