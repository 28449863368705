import * as React from 'react';

const SvgCMA = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="31px" viewBox="0 0 24 31" version="1.1">
    <title>CMAs</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Side-Menu-Icons" transform="translate(-411.000000, -663.000000)">
        <g id="Group" transform="translate(411.000000, 663.000000)">
          <g
            id="Icons/CMA-Small-Icon"
            transform="translate(0.923077, 0.923077)"
          >
            <g id="Group-5" stroke="#FFFFFF">
              <line
                x1="2.16"
                y1="10.137876"
                x2="7.58769231"
                y2="6.61165824"
                id="Line-3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <line
                x1="8.36307692"
                y1="6.61165824"
                x2="13.7907692"
                y2="10.137876"
                id="Line-4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.54461538,11.4602076 L3.54461538,14.7495342 C3.54461538,15.8541037 4.44004588,16.7495342 5.54461538,16.7495342 L6.37673206,16.7495342 L6.37673206,16.7495342 C6.75168606,15.9842733 7.03557002,15.4405084 7.22838393,15.1182396 C7.40160053,14.8287256 7.8096482,14.369457 8.45252693,13.7404338 L8.45251334,13.7404199 C8.6011366,13.5949998 8.77152796,13.4736457 8.95754628,13.3807315 C10.0298287,12.8451373 10.7362877,12.5360909 11.0769231,12.4535922 C11.446156,12.3641674 11.8892329,12.3268907 12.4061538,12.341762 L12.4061538,11.4602076"
                id="Path"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="Group-4">
                <g id="Group-2" strokeLinecap="round" strokeLinejoin="round">
                  <g id="Account-Icon">
                    <path
                      d="M0,3.76310886 L0,24.446633 C-1.82683884e-15,25.5512025 0.8954305,26.446633 2,26.446633 L14.6153846,26.446633 C15.7199541,26.446633 16.6153846,25.5512025 16.6153846,24.446633 L16.6153846,23.0760286 L16.6153846,23.0760286 C17.4083144,22.1350428 17.9088657,21.4478601 18.1170386,21.0144806 C18.6326398,19.9410889 18.609393,18.9585715 18.609393,18.2931386 C18.609393,17.6390783 18.4818384,16.6323943 17.9837104,15.638009 C17.7733211,15.2180206 17.3172125,14.5805597 16.6153846,13.7256265 L16.6153846,3.76310886 C16.6153846,2.65853936 15.7199541,1.76310886 14.6153846,1.76310886 L2,1.76310886 C0.8954305,1.76310886 -1.02344917e-15,2.65853936 0,3.76310886 Z"
                      id="Path"
                    />
                    <path
                      d="M16.7179466,24.6835241 L20.1538462,24.6835241 C21.2584157,24.6835241 22.1538462,23.7880936 22.1538462,22.6835241 L22.1538462,2 C22.1538462,0.8954305 21.2584157,-2.02906125e-16 20.1538462,0 L13.1716245,0 L13.1716245,0 L9.14772425,0 C8.17398611,1.78872794e-16 7.38461538,0.789370726 7.38461538,1.76310886 L7.38461538,1.76310886 L7.38461538,1.76310886"
                      id="Path"
                    />
                  </g>
                </g>
                <g id="Group-3" transform="translate(6.203077, 12.341762)">
                  <line
                    x1="11.0769231"
                    y1="11.9009848"
                    x2="15.5076923"
                    y2="17.1903114"
                    id="Line"
                    strokeLinecap="round"
                  />
                  <ellipse
                    id="Oval"
                    cx="6.20307692"
                    cy="6.17088102"
                    rx="6.20307692"
                    ry="6.17088102"
                  />
                </g>
              </g>
            </g>
            <path
              d="M12.2465895,17.9103211 L12.6766073,17.9103211 C14.3394785,17.9103211 15.6923077,18.9308748 15.6923077,20.1853194 C15.6923077,21.2298456 14.7540272,22.1116297 13.4801625,22.3776801 L13.4801625,23.011312 C13.4801625,23.2874544 13.2563049,23.511312 12.9801625,23.511312 L11.9429744,23.511312 C11.666832,23.511312 11.4429744,23.2874544 11.4429744,23.011312 L11.4429744,22.3776801 L11.4429744,22.3776801 C10.1691097,22.1115844 9.23076923,21.2298456 9.23076923,20.1853194 L11.2679574,20.1853194 C11.2679574,20.5923106 11.7069089,20.9234941 12.2464696,20.9234941 L12.6765473,20.9234941 C13.2161081,20.9234941 13.6550596,20.5923106 13.6550596,20.1853194 C13.6550596,19.7783282 13.2161081,19.4471447 12.6765473,19.4471447 L12.2464696,19.4471447 C10.5835984,19.4471447 9.23076923,18.4265911 9.23076923,17.1721464 C9.23076923,16.1276202 10.1690498,15.2458362 11.4429744,14.9797857 L11.4429744,14.3461538 C11.4429744,14.0700115 11.666832,13.8461538 11.9429744,13.8461538 L12.9801625,13.8461538 C13.2563049,13.8461538 13.4801625,14.0700115 13.4801625,14.3461538 L13.4801625,14.9797857 L13.4801625,14.9797857 C14.7540272,15.2458814 15.6923077,16.1276202 15.6923077,17.1721464 L13.6551195,17.1721464 C13.6551195,16.7651553 13.2161681,16.4339718 12.6766073,16.4339718 L12.2465895,16.4339718 C11.7070288,16.4339718 11.2680773,16.7651553 11.2680773,17.1721464 C11.2680773,17.5791376 11.7070288,17.9103211 12.2465895,17.9103211 Z"
              id="Shape"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgCMA;
