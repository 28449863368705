import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import App from './App';
import { DefaultError } from './crm/components/Errors';
import { unregister } from './serviceWorker';

const {
  REACT_APP_DATADOG_APP_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_ENVIRONMENT,
  REACT_APP_LOG_LEVEL,
  REACT_APP_SENTRY_DSN,
  REACT_APP_VERSION
} = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  enabled: Boolean(Number(REACT_APP_LOG_LEVEL)),
  environment: REACT_APP_ENVIRONMENT,
  logLevel: Number(REACT_APP_LOG_LEVEL),
  release: `crm-ui@${REACT_APP_VERSION}`
});

if (REACT_APP_DATADOG_APP_ID && REACT_APP_DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APP_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    sampleRate: 100,
    trackInteractions: true,
    service: 'crm-ui',
    env: REACT_APP_ENVIRONMENT,
    version: REACT_APP_VERSION
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={DefaultError} showDialog>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
