import * as React from 'react';

const AdminIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="25px" height="27px" viewBox="0 0 25 27" version="1.1">
      <title>Admin</title>
      <g
        id="CRM-Agent-Dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Side-Menu-Icons"
          transform="translate(-567.000000, -662.000000)"
          stroke="#FFFFFF"
        >
          <g id="Group" transform="translate(567.000000, 662.000000)">
            <g id="Group" transform="translate(0.827586, 0.827586)">
              <ellipse
                id="Oval"
                strokeWidth="0.2"
                cx="19.3103448"
                cy="17.9785969"
                rx="1"
                ry="1"
              />
              <g id="Group-2">
                <path
                  d="M17.8412945,14.0540731 C19.1696491,14.1999368 20.3526622,14.7811199 21.2413504,15.6483746 C22.1343233,16.5198107 22.7305202,17.6801215 22.8798001,18.9806922 L22.8798001,18.9806922 L22.8811505,20.2441799 C22.7299289,21.5448636 22.1327034,22.7049148 21.2395469,23.5760333 C20.3506608,24.4429868 19.1682965,25.0237998 17.8413236,25.1696606 L17.8413236,25.1696606 L16.5534323,25.1683181 C15.2266209,25.0230378 14.044408,24.4425776 13.1557753,23.5758768 C12.2628118,22.7049521 11.6659196,21.5449515 11.5153257,20.2443193 L11.5153257,20.2443193 L11.5153257,18.9821311 C11.6652382,17.6808101 12.2618018,16.5201294 13.1547722,15.6486957 C14.0434029,14.7814971 15.2259471,14.2006947 16.5534471,14.0554155 L16.5534471,14.0554155 Z"
                  id="Path"
                />
                <path
                  d="M9.91135632,11.9857313 C11.9363678,11.9857313 13.8081839,12.5828775 15.3748966,13.5973841 C13.0190345,14.5425446 11.351908,16.8039952 11.351908,19.4391439 C11.351908,21.3153389 12.2118621,22.9860642 13.5507126,24.1443995 C12.3449302,24.314048 11.1290556,24.402429 9.91135632,24.4089417 C7.04827586,24.4089417 4.45296552,23.9800238 2.62878161,23.3661831 C1.71733333,23.0579786 1.00027586,22.7022592 0.556137931,22.368371 C0.113287356,22.0344828 -5.94569094e-14,21.7725089 -5.94569094e-14,21.6543639 C-5.94569094e-14,16.3070155 4.42850575,11.9870155 9.91264368,11.9870155 L9.91135632,11.9857313 Z"
                  id="Path"
                />
                <ellipse
                  id="Oval"
                  cx="9.44061303"
                  cy="5.99286564"
                  rx="6.00766284"
                  ry="5.99286564"
                />
              </g>
              <g
                id="house-key"
                transform="translate(14.590038, 16.694411)"
                fillRule="nonzero"
                strokeWidth="0.5"
              >
                <path
                  d="M0.200239019,5.99286564 L0.918196022,5.99286564 C1.06837529,5.99286564 1.13505488,5.90067685 1.24288359,5.79310663 C1.28056979,5.75574109 1.30169751,5.70488278 1.30155362,5.65187701 L1.30155362,5.39358861 L1.56046268,5.39358861 C1.61359582,5.39373215 1.66457633,5.37265508 1.70203166,5.33505922 C1.82217507,5.21520382 1.80215117,5.23747695 1.80215117,4.89419109 L2.0624619,4.89419109 C2.11559504,4.89433463 2.16657555,4.87325756 2.20403088,4.8356617 C2.32277262,4.71640558 2.30274872,4.73718051 2.30274872,4.39479357 L2.56305945,4.39479357 C2.61619259,4.39493711 2.6671731,4.37386004 2.70462843,4.33626418 L2.98215971,4.0593982 C3.09794855,4.12166243 3.24100411,4.1004852 3.33367931,4.00736098 L3.43069512,3.91137677 C4.7074191,4.29701154 5.95811202,3.32897939 6.00606926,2.07569137 C6.02833625,1.51907592 5.81643592,0.97850026 5.42158003,0.584609884 C5.02672415,0.190719508 4.48483929,-0.0206468196 3.9268874,0.0015935942 C2.66157704,0.0500351536 1.70012939,1.30731835 2.08729154,2.57079407 L1.99027573,2.66757731 C1.89758838,2.7609334 1.876683,2.90364012 1.93871418,3.01955268 L0.0880050489,4.8649264 C0.0318429389,4.92126037 0.000219730285,4.99741917 0,5.07687071 L0,5.79310663 C0,5.84608601 0.0210965503,5.8968955 0.0586486511,5.93435758 C0.0962007519,5.97181966 0.147132329,5.99286564 0.200239019,5.99286564 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AdminIcon;
