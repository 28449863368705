import { Divider, PageHeader, Descriptions, Grid } from 'antd';
import React, { FC, useMemo } from 'react';
import { Client } from '@elm-street-technology/crm-axios-client';
import Icon from '@ant-design/icons';
import {
  EmailPrimary,
  EmailOther,
  CallOther,
  CallPrimary,
  AddressPrimary
} from 'src/crm/assets/icons/profile';
import { ClientRecordSectionTitle } from '../../index';

interface Props {
  client: Client;
}

export const ProfileSection: FC<Props> = ({ client }) => {
  const { xs } = Grid.useBreakpoint();

  const primaryPhone = useMemo(() => {
    const primary = client.phoneNumbers.find(({ isPrimary }) =>
      Boolean(isPrimary)
    );

    if (primary) {
      const { phone, extension } = primary;
      return `${phone}${extension ? ` x${extension}` : ''}`;
    }

    return '-';
  }, [client]);

  const secondaryPhone = useMemo(() => {
    const secondary = client.phoneNumbers.find(({ isPrimary }) => !isPrimary);

    if (secondary) {
      const { phone, extension } = secondary;
      return `${phone}${extension ? ` x${extension}` : ''}`;
    }

    return '-';
  }, [client]);

  const primaryEmail = useMemo(() => client.emailAddresses?.[0]?.email || '-', [
    client
  ]);

  const secondaryEmail = useMemo(
    () => client.emailAddresses?.[1]?.email || '-',
    [client]
  );

  const address = useMemo(() => {
    const { streetAddress1, streetAddress2, city, state, zipCode } = client;
    if (!streetAddress1 && !streetAddress2 && !city && !state && !zipCode) {
      return '-';
    }

    return (
      <>
        {streetAddress1 || '-'}
        <br />
        {streetAddress2 ? (
          <>
            {streetAddress2}
            <br />
          </>
        ) : (
          '-'
        )}
        {city ? `${city}, ` : ''} {state || ''} {zipCode || ''}
      </>
    );
  }, [client]);

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Profile</ClientRecordSectionTitle>}
      footer={<Divider />}
    >
      <Descriptions column={xs ? 1 : 2} colon={false}>
        <Descriptions.Item label={<Icon component={CallPrimary} />}>
          {primaryPhone}
        </Descriptions.Item>

        {secondaryPhone !== '-' && (
          <Descriptions.Item label={<Icon component={CallOther} />}>
            {secondaryPhone}
          </Descriptions.Item>
        )}

        <Descriptions.Item label={<Icon component={EmailPrimary} />}>
          {primaryEmail}
        </Descriptions.Item>

        {secondaryEmail !== '-' && (
          <Descriptions.Item label={<Icon component={EmailOther} />}>
            {secondaryEmail}
          </Descriptions.Item>
        )}

        <Descriptions.Item label={<Icon component={AddressPrimary} />}>
          {address}
        </Descriptions.Item>
      </Descriptions>
    </PageHeader>
  );
};
