import * as React from 'react';

const SvgMLSSearch = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1">
    <title>MLS Search</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Side-Menu-Icons"
        transform="translate(-462.000000, -596.000000)"
        stroke="#FFFFFF"
      >
        <g id="Group-4" transform="translate(462.000000, 596.000000)">
          <g id="Group-3" strokeLinecap="round" strokeLinejoin="round">
            <g id="Account-Icon">
              <line
                x1="0.711111111"
                y1="6.90283401"
                x2="10.6666667"
                y2="1.23481781"
                id="Line-3"
              />
              <line
                x1="11.2460052"
                y1="1.65291822"
                x2="13.1555556"
                y2="0.526315789"
                id="Line-3"
              />
              <line
                x1="10.6666667"
                y1="1.23481781"
                x2="20.6222222"
                y2="6.90283401"
                id="Line-4"
              />
              <line
                x1="13.3333333"
                y1="0.708502024"
                x2="23.2888889"
                y2="6.37651822"
                id="Line-4"
              />
              <path
                d="M3.55555556,9.38439272 L3.55555556,16.8521018 C3.55555556,17.9566713 4.45098606,18.8521018 5.55555556,18.8521018 L15.7777778,18.8521018 C16.8823473,18.8521018 17.7777778,17.9566713 17.7777778,16.8521018 L17.7777778,9.38439272 L17.7777778,9.38439272"
                id="Path"
              />
              <path
                d="M12.4802139,18.9413932 L18.0888889,18.9413932 C19.1934584,18.9413932 20.0888889,18.0459627 20.0888889,16.9413932 L20.0888889,9.47368421 L20.0888889,9.47368421"
                id="Path"
              />
              <path
                d="M14.6135472,18.9413932 L20.2222222,18.9413932 C21.3267917,18.9413932 22.2222222,18.0459627 22.2222222,16.9413932 L22.2222222,9.47368421 L22.2222222,9.47368421"
                id="Path"
              />
            </g>
          </g>
          <g id="Group-3" transform="translate(6.947368, 8.210526)">
            <line
              x1="5.89473684"
              y1="5.90977444"
              x2="8"
              y2="8.61654135"
              id="Line"
              strokeLinecap="round"
            />
            <circle id="Oval" cx="3.15789474" cy="3.15789474" r="3.15789474" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgMLSSearch;
