import {
  Configuration,
  ListingsApi,
  OptionsApi,
  ListingStatsApi,
  ProspectiveAgentOptionsApi,
  ProspectiveAgentApi,
  AgentSearchApi,
  OfficeSearchApi
} from '@elm-street-technology/mls-axios-client';
import { MLS_HOST } from 'src/common/constants';

const mlsConfig = () => {
  const { sessionStorage } = window;
  const accessToken = sessionStorage.getItem('mlsAccessToken') || '';
  return new Configuration({
    basePath: MLS_HOST,
    accessToken
  });
};

export const listingsApi = () => {
  return new ListingsApi(mlsConfig());
};

export const listingsOptionsApi = () => {
  return new OptionsApi(mlsConfig());
};

export const listingStatsApi = () => {
  return new ListingStatsApi(mlsConfig());
};

export const prospectiveAgentOptionsApi = () => {
  return new ProspectiveAgentOptionsApi(mlsConfig());
};

export const prospectiveAgentApi = () => {
  return new ProspectiveAgentApi(mlsConfig());
};

export const agentSearchApi = () => {
  return new AgentSearchApi(mlsConfig());
};

export const officeSearchApi = () => {
  return new OfficeSearchApi(mlsConfig());
};
