import * as React from 'react';

const SvgHelp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="29px" viewBox="0 0 24 29" version="1.1">
    <title>Help</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Side-Menu-Icons" transform="translate(-489.000000, -658.000000)">
        <g id="Group" transform="translate(489.000000, 658.000000)">
          <g transform="translate(1.000000, 6.000000)" stroke="#FFFFFF">
            <path
              d="M11.3046667,11.2117147 C13.3319486,11.2117147 15.1675052,11.9940996 16.4982028,13.2574302 C17.8374571,14.5288843 18.6660159,16.2867561 18.6719661,18.2313547 L18.6719661,18.2313547 L18.6719661,20.792982 C18.6650462,21.1939031 18.4943967,21.5539308 18.2237493,21.8112016 C17.9562915,22.0654405 17.5910522,22.2191727 17.200647,22.2115312 L17.200647,22.2115312 L1.79935295,22.2115312 C1.39565476,22.2115312 1.03171896,22.054392 0.767952454,21.803662 C0.494610491,21.5438298 0.328033877,21.1841472 0.328033877,20.7882191 L0.328033877,20.7882191 L0.328035509,18.2300773 C0.332992809,16.2896628 1.15972656,14.5329932 2.49730179,13.2614301 C3.8259671,11.9983371 5.65935058,11.2146419 7.68613521,11.2117147 L7.68613521,11.2117147 Z"
              id="Path"
              strokeLinejoin="round"
            />
            <circle id="Oval" cx="9.5" cy="5.5" r="5.5" />
          </g>
          <text
            id="?"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="16"
            fontWeight="400"
            fill="#FFFFFF"
          >
            <tspan x="16" y="12">
              ?
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgHelp;
