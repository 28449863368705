import React, { FC, createContext, useReducer } from 'react';
import {
  initialProfileState,
  profileReducer,
  ProfileDispatch
} from './Profile.reducer';

export const ProfileContext = createContext(initialProfileState);

export const ProfileDispatchContext = createContext<ProfileDispatch>(() => ``);

export const ProfileProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(profileReducer, initialProfileState);

  return (
    <ProfileContext.Provider value={state}>
      <ProfileDispatchContext.Provider value={dispatch}>
        {children}
      </ProfileDispatchContext.Provider>
    </ProfileContext.Provider>
  );
};
