import styled from 'styled-components';
import { Button, Tooltip, Modal, message } from 'antd';
import {
  InfoCircleOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import React, { FC, useReducer, useContext, useCallback } from 'react';
import {
  ClientAbility,
  ClientStatusEnum,
  ClientUpdateBodyStatusEnum,
  Client,
  ClientListItem
} from '@elm-street-technology/crm-axios-client';

import { useGlobalDrawer } from 'src/common/hooks';
import { Spin } from 'src/crm/components';
import { clientRoleToDisplay, getClientRoleColor } from 'src/common/utils';
import Call from 'src/crm/assets/icons/CallActive';
import CallInactive from 'src/crm/assets/icons/CallInactive';
import { CallEkata } from 'src/crm/assets/icons/CallEkata';
import Invite from 'src/crm/assets/icons/InviteActive';
import InviteInactive from 'src/crm/assets/icons/InviteInactive';
import Mail from 'src/crm/assets/icons/MailActive';
import MailInactive from 'src/crm/assets/icons/MailInactive';
import Message from 'src/crm/assets/icons/MessageActive';
import StopContactActive from 'src/crm/assets/icons/StopContactActive';
import MessageInactive from 'src/crm/assets/icons/MessageInactive';
import { FeatureTogglesContext } from 'src/common/contexts';
import { bulkActionApi } from 'src/common/services';

import {
  ClientRecordDrawerStateContext,
  ClientRecordDrawerDispatchContext
} from '../../ClientRecordDrawer.context';
import {
  inviteClient,
  clientInviteReducer,
  initialClientInviteState,
  clientDeleteReducer,
  initialClientDeleteState
} from './ClientRecordHeader.reducer';
import {
  ClientsStateContext,
  ClientsDispatchContext
} from '../../../../pages/Clients/Clients.context';
import { listClients } from '../../../../pages/Clients/actions';

import {
  clientStopContactReducer,
  initialClientStopContactState
} from './ClientStopContact/ClientStopContact.reducer';
import { updateClient } from '../../actions';
import ClientStopContact from './ClientStopContact/ClientStopContact';

const DetailsActions = styled(Button)`
  height: auto;
  padding: 0;
  border: 0px;
`;

const Container = styled.div`
  background: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 19px;

  h1 {
    font-size: 19px;
    color: #082746;
  }
`;

const Status = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  button {
    &.new {
      background-color: #d9eefc;
      border: 1px solid #98d5fd;
      color: #082746;
    }
    &.marketing {
      background-color: #d9eefc;
      border: 1px solid #4ea5f2;
      color: #082746;
    }
    &.engaged {
      background-color: #f4fff5;
      border: 1px solid #6dbc6d;
      color: #082746;
    }
    &.active {
      background-color: #fff8e5;
      border: 1px solid #facb3e;
      color: #082746;
    }
    &.pending {
      background-color: #ffeadb;
      border: 1px solid #ff7043;
      color: #082746;
    }
    &.sold {
      background-color: #ffeded;
      border: 1px solid #f33b3b;
      color: #082746;
    }
    font-size: 14px;
    color: #a7a7a7;
    padding: 0 10px;
    width: 87px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    height: 27px;
    border-radius: 4px;
    border: 1px solid #a7a7a7;
    margin: 0 12px 12px 0;
  }
`;

const Name = styled.div`
  h1 {
    margin-bottom: 0;
    margin-left: 36px;
    margin-right: 20px;
  }
  display: flex;
  margin-top: 20px;
  margin-bottom: 6px;
  justify-content: center;
  align-items: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 23px;
  margin-bottom: 33px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: 23px;
  align-items: center;
  font-size: 10px;
  color: #2e7fc2;
`;

const ReadyToBuy = styled.p`
  font-size: 12px;
  text-align: center;
  color: #082746;
  margin-bottom: 1rem;
`;

const Role = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  span {
    background-color: #ffffff;
    padding: 1px 10px;
    border-radius: 11px;
    border: 1px solid rgb(127, 202, 148);
    font-size: 16px;
    color: #81cb96;
    margin-bottom: 1rem;
  }
`;

interface Props {
  onUpdateSuccess: (client: Client) => void;
}

export const ClientRecordHeader: FC<Props> = ({ onUpdateSuccess }) => {
  const {
    abilities: { data: clientAbilities },
    client: { data: client, updating }
  } = useContext(ClientRecordDrawerStateContext);
  const dispatch = useContext(ClientRecordDrawerDispatchContext);
  const dispatchClients = useContext(ClientsDispatchContext);
  const { filter } = useContext(ClientsStateContext);

  const [{ inviting, invited }, inviteDispatch] = useReducer(
    clientInviteReducer,
    initialClientInviteState
  );

  const [{ deleting }, deleteDispatch] = useReducer(
    clientDeleteReducer,
    initialClientDeleteState
  );

  const [{ stopping }, stopContactDispatch] = useReducer(
    clientStopContactReducer,
    initialClientStopContactState
  );

  const { quickCall, quickEmail, quickMessage } = useGlobalDrawer();

  if (!client || !clientAbilities) {
    return <Spin />;
  }

  const canManage = clientAbilities.includes(ClientAbility.Manageclient);
  const canUpdate = clientAbilities.includes(ClientAbility.Updateclient);
  const canDelete = client.source !== 'PPC';

  const enableTextButton = client.canText && client.isTextable;
  const enablePhoneButton = client.canCall && client.isCallable;
  const enableEmailButton = client.canEmail && client.isEmailable;

  const showEkataCallButton =
    !enablePhoneButton && hasEkataPhoneNumbers(client);

  const clientInvited = client.isInvited || inviting || invited;

  const {
    featureToggles: { 'aiva-8-stop-contact': enableStopContact }
  } = useContext(FeatureTogglesContext);

  const isAivaContacted =
    client.tags && client.tags.includes('Concierge - Contacting');
  const isAivaStopped =
    client.tags && client.tags.includes('Concierge - Stopped');
  const isStopAivaButtonEnabled =
    isAivaContacted && !isAivaStopped && enableStopContact;

  const handleStopContactClick = () => {
    dispatch({ type: 'CloseNestedDrawer' });
    ClientStopContact(stopContactDispatch, client.id);
  };

  const handleInviteClick = () => {
    dispatch({ type: 'CloseNestedDrawer' });
    inviteClient(inviteDispatch, client.id);
  };

  const handleEditClick = () => dispatch({ type: 'OpenUpdateClientDrawer' });

  const onDelete = () => {
    bulkActionApi
      .bulkActionDelete({
        bulkActionDeleteBody: {
          leadFilter: {
            selectedLeads: [client.id]
          }
        }
      })
      .then(() => {
        message.success('Success deleting client');
        dispatchClients({ type: 'CloseDrawer' });
        listClients(dispatchClients, filter);
      })
      .catch(error => {
        message.error('Error deleting client');
        deleteDispatch({ type: 'ClientDeleteFailure' });
      });
  };

  const handleDeleteClick = useCallback(() => {
    if (!updating) {
      Modal.confirm({
        title: 'Are you sure you want to delete this client?',
        icon: <ExclamationCircleOutlined />,
        cancelText: 'No',
        okButtonProps: {
          danger: true
        },
        okText: 'Yes - Delete',
        onOk: () => onDelete()
      });
    }
  }, [onDelete, updating]);

  const handleQuickMessageClick = () => {
    dispatch({ type: 'CloseNestedDrawer' });
    enableTextButton && quickMessage(client);
  };
  const handleQuickCallClick = () => {
    dispatch({ type: 'CloseNestedDrawer' });
    enablePhoneButton && quickCall(client);
  };
  const handleQuickEmailClick = () => {
    dispatch({ type: 'CloseNestedDrawer' });
    enableEmailButton && quickEmail([client]);
  };

  const handleMarketingStatusClick = () =>
    updateClient(
      dispatch,
      client.id,
      {
        status: ClientUpdateBodyStatusEnum.Marketing
      },
      onUpdateSuccess
    );

  const handleNewStatusClick = () =>
    updateClient(
      dispatch,
      client.id,
      {
        status: ClientUpdateBodyStatusEnum.New
      },
      onUpdateSuccess
    );

  const handleEngagedStatusClick = () =>
    updateClient(
      dispatch,
      client.id,
      {
        status: ClientUpdateBodyStatusEnum.Engaged
      },
      onUpdateSuccess
    );

  const handleActiveStatusClick = () =>
    updateClient(
      dispatch,
      client.id,
      {
        status: ClientUpdateBodyStatusEnum.Active
      },
      onUpdateSuccess
    );

  const handlePendingStatusClick = () =>
    updateClient(
      dispatch,
      client.id,
      {
        status: ClientUpdateBodyStatusEnum.Pending
      },
      onUpdateSuccess
    );

  const handleSoldStatusClick = () =>
    updateClient(
      dispatch,
      client.id,
      { status: ClientUpdateBodyStatusEnum.Sold },
      onUpdateSuccess
    );

  return (
    <Container>
      <Name>
        <h1>{client.displayName || 'N/A'}</h1>
        <Button
          shape="circle"
          type="text"
          disabled={!canUpdate}
          onClick={handleEditClick}
          icon={<EditFilled />}
        />
        <Button
          shape="circle"
          type="text"
          disabled={!canDelete || deleting}
          onClick={handleDeleteClick}
          icon={<DeleteFilled />}
        />
      </Name>
      <ReadyToBuy>
        {client.readyToBuyAfterIsoString
          ? `Ready to buy After ${moment(
              client.readyToBuyAfterIsoString
            ).format('MM/DD/YY')}`
          : ''}
      </ReadyToBuy>
      {client.type && (
        <Role>
          <span
            style={{
              color: getClientRoleColor(client.type).foreground,
              border: `1px solid ${getClientRoleColor(client.type).border}`
            }}
          >
            {clientRoleToDisplay(client.type)}
          </span>
        </Role>
      )}
      <ActionsContainer>
        <ButtonContainer
          onClick={handleQuickMessageClick}
          className="message-client-action-drawer"
        >
          {enableTextButton ? <Message /> : <MessageInactive />}
          Text
        </ButtonContainer>
        <ButtonContainer
          onClick={handleQuickCallClick}
          className="call-client-action-drawer"
        >
          {showEkataCallButton ? (
            <EkataIcon />
          ) : enablePhoneButton ? (
            <Call />
          ) : (
            <CallInactive />
          )}
          Call
        </ButtonContainer>
        <ButtonContainer
          onClick={handleQuickEmailClick}
          className="email-client-action-drawer"
        >
          {enableEmailButton ? <Mail /> : <MailInactive />}
          Email
        </ButtonContainer>
        <ButtonContainer className="invite-client-action-drawer">
          <DetailsActions
            disabled={!enableEmailButton || inviting}
            onClick={handleInviteClick}
            type="text"
          >
            {enableEmailButton ? <Invite /> : <InviteInactive />}
          </DetailsActions>
          {clientInvited ? 'Re-Invite' : 'Invite'}
        </ButtonContainer>
        {isStopAivaButtonEnabled ? (
          <ButtonContainer className="stop-contact-action-drawer">
            <DetailsActions
              disabled={stopping}
              onClick={handleStopContactClick}
              type="text"
            >
              <StopContactActive />
            </DetailsActions>
            Stop AIVA
          </ButtonContainer>
        ) : (
          <></>
        )}
      </ActionsContainer>
      <Status>
        {client.isContact ? (
          <Button
            disabled={!canManage}
            loading={updating}
            className={
              client.status === ClientStatusEnum.Marketing ? client.status : ''
            }
            onClick={handleMarketingStatusClick}
          >
            {updating ? '' : 'Marketing'}
          </Button>
        ) : (
          <Button
            disabled={!canManage}
            loading={updating}
            className={
              client.status === ClientStatusEnum.New ? client.status : ''
            }
            onClick={handleNewStatusClick}
          >
            {updating ? '' : 'New'}
          </Button>
        )}
        <Button
          disabled={!canManage}
          loading={updating}
          className={
            client.status === ClientStatusEnum.Engaged ? client.status : ''
          }
          onClick={handleEngagedStatusClick}
        >
          {updating ? '' : 'Engaged'}
        </Button>
        <Button
          disabled={!canManage}
          loading={updating}
          className={
            client.status === ClientStatusEnum.Active ? client.status : ''
          }
          onClick={handleActiveStatusClick}
        >
          {updating ? '' : 'Active'}
        </Button>
        <Button
          disabled={!canManage}
          loading={updating}
          className={
            client.status === ClientStatusEnum.Pending ? client.status : ''
          }
          onClick={handlePendingStatusClick}
        >
          {updating ? '' : 'Pending'}
        </Button>
        <Button
          disabled={!canManage}
          loading={updating}
          className={
            client.status === ClientStatusEnum.Sold ? client.status : ''
          }
          onClick={handleSoldStatusClick}
        >
          {updating ? '' : 'Sold'}
        </Button>
      </Status>
    </Container>
  );
};

const EkataIcon: FC = () => (
  <div>
    <CallEkata />
    <Tooltip
      placement="topLeft"
      title="Phone number acquired via public records. Accuracy not guaranteed."
    >
      <InfoCircleOutlined style={{ position: 'absolute', color: '#f5222d' }} />
    </Tooltip>
  </div>
);

const hasEkataPhoneNumbers = (client: Client | ClientListItem) =>
  client?.sellerFields?.current_residents?.some(
    ({ phones }) => phones && phones.length
  ) || false;
