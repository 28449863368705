import { Layout, Menu } from 'antd';
import styled from 'styled-components';

export const CRMSider = styled(Layout.Sider)`
  background-color: ${({ theme }) => theme.defaultNavBar};
  color: #ffffff;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 64px);
  top: 64px;
  position: sticky;
  left: 0;

  @media (max-width: 760px) {
    overflow: visible;
    z-index: 100;
  }
`;
export const CRMMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.defaultNavBar};
  color: #ffffff;
  height: 100%;

  .ant-menu-item-selected,
  .ant-menu-item:active {
    background: ${({ theme }) => theme.defaultNavBar} !important;
  }

  .ant-menu-item::after {
    border-right-color: ${({ theme }) => theme.defaultNavBar};
  }

  [role='img'] {
    min-width: fit-content;
  }

  svg {
    min-height: 30px;
    min-width: 30px;
    color: #ffffff;

    &[data-icon^='menu'] {
      color: black;
    }
  }

  .ant-menu-inline > .ant-menu-item {
    height: 35px !important;
    line-height: 35px !important;
  }

  &.ant-menu-inline > .ant-menu-item:not(:last-child) {
    &:first-child {
      margin-bottom: 0;
    }
    margin-bottom: 16px;
    > span,
    a {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .ant-menu-item > a {
    color: #ffffff;
  }

  .ant-menu-submenu,
  .ant-menu-submenu > .ant-menu {
    background-color: ${({ theme }) => theme.defaultNavBar};
    color: #ffffff;
  }

  .ant-menu-submenu-title {
    font-size: 1rem;
    font-weight: 600;
  }

  .ant-menu-sub.ant-menu-inline {
    padding-bottom: 20px;
    width: max-content;
  }

  .ant-menu-submenu-arrow {
    display: none;
  }

  .ant-menu-submenu-title {
    .anticon > span[role='img'] {
      margin-right: 0;
    }
  }

  .menu-items.ant-menu-item-only-child {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-top: 10px !important;
    height: 30px !important;
    svg {
      min-height: 20px;
      min-width: 20px;
    }
  }

  .ant-menu-item-group {
    padding-left: 24px;

    .ant-menu-item-group-title {
      color: #ffffff;
      padding-left: 0;

      span {
        font-size: 1rem;
      }

      .anticon {
        width: 30px;
        margin-right: 15px;
        padding-right: 15px;

        svg {
          vertical-align: middle;
        }
      }
    }
  }
`;
